function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    //hour: "numeric",
    //minute: "numeric",
    //second: "numeric",
  };
  return date.toLocaleDateString("ru-RU", options);
}

export default formatDate;
